$('#modalImage').on('shown.bs.modal', function() {
    'use strict';
    $('.big-carousel-box').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        centerMode: true,
        asNavFor: '.mini-carousel-box'
    });

    $('.mini-carousel-box').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.big-carousel-box',
        dots: true,
        arrows: true,
        focusOnSelect: true
    });
});
