/*
 *
 * Application Main
 *
 */
!(function(APP) {
    'use strict';


    var breakpoint = 768;

    $.extend(true, APP, {
        breakpoint: breakpoint,
        init: {
            services: function (opts) {
                if (typeof APP.ServiceManager === 'undefined') {
                    return;
                }
                var list = opts.list;
                function overrideServiceMethod(serviceMethod, workWith) {
                    if (workWith === 'mock') {
                        serviceMethod.url = serviceMethod.mockUrl;
                        serviceMethod.method = 'GET';
                        delete serviceMethod.mockUrl;
                    } else if (workWith === 'real') {
                        delete serviceMethod.mockUrl;
                    }
                }
                // set default datas for every ajax request
                var antiForgeryToken = $('input[name="__RequestVerificationToken"]').val();
                var cid = $('input[name="cid"]').val();
                var defaultDatas = {};
                if (antiForgeryToken) {
                    defaultDatas.__RequestVerificationToken = antiForgeryToken;
                }
                if (cid) {
                    defaultDatas.cid = cid;
                }
                APP.ServiceManager.setDefaultData(defaultDatas);
                for (var i = 0; i < list.length; i++) {
                    for (var methodKey in list[i].methods) {
                        if (list[i].methods.hasOwnProperty(methodKey)) { // eslint-disable-line
                            overrideServiceMethod(list[i].methods[methodKey], opts.workWith);
                        }
                    }
                    APP.ServiceManager.registerService(list[i]);
                }
            },
            scroll: function() {

                $('.slider-scroll').on('click', function(e) {
                    e.preventDefault();

                    var el = $(this).parents('.home-slider').next();

                    $('html, body').animate({
                        scrollTop: el.offset().top
                    }, 500);
                });
            },
            homeSlider: function () {
                var el, owlCarousel, owlItem, item, homeSlider, homeSliderLength, sliderNext, uiText, desc, holderImage, iconHolder, initText, initImage, currentIndex, currentText, currentImage;

                el = '.home-slider',
                owlCarousel = '.owl-carousel',
                owlItem = '.owl-item',
                item = '.item',
                sliderNext = '.slider-next',
                uiText = '.ui-text',
                desc = '.desc',
                holderImage = '.holder-image',
                iconHolder = '.icon-holder',

                homeSlider = $(el + ' ' + owlCarousel);
                homeSliderLength = $(el + ' ' + item + ':not(.cloned)').length;

                homeSlider.owlCarousel({
                    nav: true,
                    loop: true,
                    dots: true,
                    items: 1,
                    video: true,
                    beforeInit: homeSliderLength > 1 ? beforeInit() : ''
                });

                $(el + ' ' + iconHolder).append( $(el + ' .owl-next' ) );

                function beforeInit() {
                    initText = $(el + ' ' + item).eq(1).find(uiText).text();
                    initImage = $(el + ' ' + item).eq(1).attr('style');

                    $(el + ' ' + sliderNext + ' ' + desc).text(initText);
                    $(el + ' ' + sliderNext + ' ' + holderImage).attr('style', initImage);
                    $(el + ' ' + sliderNext).removeAttr('hidden');

                    if ( $(el + ' ' + item).eq(0).hasClass('blue') ) {
                        $(el).addClass('blue');
                    } else {
                        $(el).removeClass('blue');
                    }
                }

                homeSlider.on('changed.owl.carousel', function(event) {
                    if ( homeSliderLength - 1 === event.page.index ) {
                        currentIndex = 0;

                    } else if (event.page.index === 0 ) {
                        currentIndex = 1;

                    } else {
                        currentIndex = event.page.index + 1;
                    }

                    currentText = $(el + ' ' + owlItem + ':not(.cloned)').eq(currentIndex).find(uiText).text();
                    currentImage = $(el + ' ' + owlItem + ':not(.cloned)').eq(currentIndex).find(item).attr('style');

                    $(el + ' ' + sliderNext + ' ' + desc).text(currentText);
                    $(el + ' ' + sliderNext + ' ' + holderImage).attr('style', currentImage);

                    if ( $(el + ' ' + owlItem + ':not(.cloned)').eq(event.page.index).find(item).hasClass('blue') ) {
                        $(el).addClass('blue');
                    } else {
                        $(el).removeClass('blue');
                    }
                });
            },
            homeReportsSlider: function () {
                var el, pagination, navPrev, navNext;

                el = '.reports-slider',
                pagination = '.swiper-pagination',
                navPrev = '.swiper-button-prev',
                navNext = '.swiper-button-next';

                if ( !$(el).length > 0 ) {
                    return;
                }

                var swiper = new Swiper(el, { // eslint-disable-line
                    pagination: {
                        el: pagination,
                        type: 'progressbar'
                    },
                    navigation: {
                        nextEl: navNext,
                        prevEl: navPrev
                    },
                    loop: false,
                    clickable: true,
                    spaceBetween: 0,
                    on: $(window).width() >= 768 ? {
                        beforeInit: function () {
                            var reportsSliderLength = $('.reports-slider .swiper-slide').length;
                            var html = '<div class="swiper-slide fake"></div>';
                            if ( reportsSliderLength % 3 === 1 ) {
                                $('.reports-slider .swiper-wrapper').append(html);
                                $('.reports-slider .swiper-wrapper').append(html);
                            } else if (reportsSliderLength % 3 === 2 ) {
                                $('.reports-slider .swiper-wrapper').append(html);
                            }
                        }
                    } : null,
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                            direction: 'horizontal'
                        },
                        768: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            direction: 'vertical'
                        }
                    }
                });
            },
            homeFastAccessSlider: function () {
                var el, owlCarousel, slider, item;

                el = '.fast-access-slider',
                owlCarousel = '.owl-carousel',
                item = '.item';

                if ( $(el).find(item).length > 4 ) {
                    slider = $(el + ' ' + owlCarousel);
                    slider.owlCarousel({
                        loop: true,
                        dots: true,
                        margin: 20,
                        responsive: {
                            0: {
                                items: 1
                            },
                            768: {
                                items: 4
                            }
                        }
                    });
                } else {
                    $(el).addClass('not-slider');
                }
            },
            homeAnnouncementsSlider: function () {
                var el, pagination;

                el = '.announcements-slider',
                pagination = '.swiper-pagination';

                var swiper = new Swiper(el, { // eslint-disable-line
                    pagination: {
                        el: pagination,
                        type: 'progressbar'
                    },
                    clickable: true,
                    loop: false,
                    spaceBetween: 20,
                    slidesPerView: 'auto',
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                            slidesPerGroup: 1
                        },
                        768: {
                            slidesPerView: 3,
                            slidesPerGroup: 1
                        }
                    }
                });
            },
            headerNav: function () {
                var nav, navOpened, subMenu, activeClass, selectedFirst, selectedSecond, forwardIcon;

                nav = '.header-nav',
                navOpened = '.header-nav-opened',
                subMenu = '.sub-menu',
                selectedFirst = '.level-selected-1',
                selectedSecond = '.level-selected-2',
                forwardIcon = '.icon-forward',
                activeClass = 'active';

                $(nav + ' > ul > li').each(function() {
                    $(this).find(selectedFirst).find('span').html( $(this).children('span').text() );

                    if ( $(this).find(navOpened).length < 1 ) {
                        $(this).find(forwardIcon).addClass('passive');
                    }
                });

                $(navOpened + ' > ul > li').each(function() {
                    $(this).find(subMenu).siblings().addClass('non-redirect');
                    $(this).find(selectedSecond).find('span').html( $(this).children('a').find('span').text() );
                });

                $(navOpened + ' > ul > li').click(function() {
                    if ( !$(this).hasClass(activeClass) ) {
                        $(this).addClass(activeClass).find(subMenu).slideDown(150);
                        $(this).siblings().removeClass(activeClass).find(subMenu).slideUp(150);
                    } else {
                        $(this).removeClass(activeClass).find(subMenu).slideUp(150);
                    }
                });

                if ( $(window).width() >= 768 ) {
                    $(document).mouseover(function(e) {
                        if ( !$(e.target).is(navOpened) && !$(e.target).is(navOpened + ' *') ) {
                            $(navOpened + ' > ul > li').removeClass(activeClass).find(subMenu).slideUp(150);
                        }
                    });
                }
            },
            headerSearch: function () {
                var el, button, item, searchInput;

                el = '.search',
                button = '.search-button',
                item = '.search-opened';
                searchInput = $('#search-text');

                $(button).click(function () {
                    searchInput.focus();
                    if ( $(el).hasClass('active') ) {
                        $(el).removeClass('active');
                        $(item).removeClass('active');
                    } else {
                        $(el).addClass('active');
                        $(item).addClass('active');
                    }
                });

                $(document).click(function (e) {
                    if ( !$(e.target).is(item) && !$(e.target).is(item + ' *') && !$(e.target).is(el) && !$(e.target).is(el + ' *') ) {
                        $(el).removeClass('active');
                        $(item).removeClass('active');
                    }
                });
            },
            headerNavMobile: function () {
                var el, item, activeClass, navOpened, subMenu, selectedFirst, selectedSecond;

                el = '.header-nav-button',
                item = '.header-nav',
                navOpened = '.header-nav-opened',
                subMenu = '.sub-menu',
                selectedFirst = '.level-selected-1',
                selectedSecond = '.level-selected-2',
                activeClass = 'active';

                $(el).click(function() {
                    if ( !$(this).hasClass(activeClass) ) {
                        $(this).addClass(activeClass);
                        $(item).addClass(activeClass);
                    } else {
                        $(this).removeClass(activeClass);
                        $(item).removeClass(activeClass);
                    }
                });

                $(document).mouseover(function(e) {
                    if ( !$(e.target).is(el) && !$(e.target).is(el + ' *') && !$(e.target).is(item + ' *') && !$(e.target).is(item + ' *') ) {
                        $(el).removeClass(activeClass);
                        $(item).removeClass(activeClass);
                    }
                });

                $(item + ' > ul > li > span').click(function () {
                    $(this).siblings(navOpened).addClass('active');
                });

                $(navOpened + ' > ul > li > a').click(function () {
                    $(this).parent().find(subMenu).addClass('active');
                });

                $(selectedFirst).click(function () {
                    $(navOpened + '.active').removeClass('active');
                });

                $(selectedSecond).click(function () {
                    $(subMenu + '.active').removeClass('active');
                });

                $(document).click(function(e) {
                    if ( !$(e.target).is(item) && !$(e.target).is(item + ' *') && !$(e.target).is(el) && !$(e.target).is(el + ' *') ) {
                        $(item).removeClass(activeClass);
                        $(navOpened + '.active').removeClass(activeClass);
                        $(subMenu + '.active').removeClass(activeClass);
                    }
                });
            },
            pageNavMobile: function () {
                var el, activeClass;

                el = '.mobile-page-nav',
                activeClass = 'active';

                $(document).on('click', el, function() {
                    if ( $(el).hasClass(activeClass) ) {
                        $(el).removeClass(activeClass);
                    } else {
                        $(el).addClass(activeClass);
                    }
                });

                $(document).click(function (e) {
                    if ( !$(e.target).is(el) && !$(e.target).is(el + ' *') ) {
                        $(el).removeClass('active');
                    }
                });
            },
            history: function () {
                var holder, el, owlCarousel, owlNav, slider;

                holder = '.history',
                el = '.history-slider',
                owlCarousel = '.owl-carousel',
                owlNav = '.owl-nav';

                slider = $(el + ' ' + owlCarousel);
                slider.owlCarousel({
                    loop: false,
                    nav: true,
                    navText: ["<i class='ms-icon ms-icon-read-more-2'></i>", "<i class='ms-icon ms-icon-read-more-2'></i>"],
                    dots: false,
                    margin: 20,
                    responsive: {
                        0: {
                            items: 1
                        },
                        768: {
                            items: 3
                        }
                    }
                });

                $(holder).find(owlNav).width( $(holder).width() );
            },
            fileInputChange: function () {
                var fileName, defaultName;

                $('input[type=file]').on('change', function() {
                    if ( $(this)[0].files.length ) {
                        fileName = $(this)[0].files[0].name;
                    } else {
                        defaultName = $(this).parent().find('#fileFake').attr('data-file');
                        fileName = defaultName;
                    }

                    $(this).parent().find('#fileFake').attr('placeholder', fileName);
                });
            },
            tableFilter: function () {
                var button, target;

                button = '.filter-table-button',
                target = '.filter-table';

                $(document).on('click', button, function() {
                    if ( $(button).hasClass('active') ) {
                        $(button).removeClass('active');
                        $(target).slideUp(150);
                    } else {
                        $(button).addClass('active');
                        $(target).slideDown(150);
                    }
                });
            },
            scrollTop: function () {
                var el;

                el = '.scroll-top';

                $(el).click(function () {
                    window.scrollTo(0, 0);
                });
            },
            accordionScrollTop: function () {
                $('.accordion-collapse').on('shown.bs.collapse', function () {
                    var $panel = $(this).parents('.accordion-item');
                    var header = $('header');
                    var top = $panel.offset().top - header.height();
                    window.scrollTo(0, top);
                });
            },
            print: function () {
                var el;

                el = '.print';

                $(document).on('click', el, function() {
                    window.print();
                });
            },
            _fontSize: function (sizeDeg) {
                var filterList, tagNames, oldSize, newSize, fontSizeAction, fontSizeActionVal;
                filterList = ['SCRIPT', 'IMG'];

                fontSizeAction = '.font-size-action';
                fontSizeActionVal = parseFloat( $(fontSizeAction).val() );

                $('.page-area *:not(.page-head *)').each(function () {
                    tagNames = $(this)[0].tagName;

                    if ( $.inArray(tagNames, filterList) === -1 ) {
                        oldSize = parseFloat( $(this).css('font-size') );

                        if ( sizeDeg === 'minus' ) {
                            newSize = oldSize - 1;
                            $(fontSizeAction).val( fontSizeActionVal - 1 );
                        } else if ( sizeDeg === 'plus' ) {
                            newSize = oldSize + 1;
                            $(fontSizeAction).val( fontSizeActionVal + 1 );
                        } else {
                            newSize = oldSize + (fontSizeActionVal * -1);
                            $(fontSizeAction).val( 0 );
                        }

                        $(this).css('font-size', newSize + 'px');
                    }
                });
            },
            tableSwipeAppend: function () {
                var target, item, html;

                item = '.table-holder',
                target = '> table > thead > tr > th:first-child',
                html = '<div class="table-swipe animation-swipe"><i class="ms-icon ms-icon-swipe-right"></i></div>';

                $(item).find(target).append( html );
            },
            announcements: function(opts) {

                var obj = {
                    mustache: '#mustache-announcements',
                    loadMore: '.announcements-load-more',
                    page: '.page-announcements',
                    target: '.announcements',
                    item: '.list-item',
                    inputPageIndex: '.page-index-announcements',
                    inputPageSize: '.page-size-announcements'
                };

                var getData = function () {
                    var DOM, $form;
                    DOM = obj;
                    $form = $(obj.page).find('form');

                    APP.Helper.sendRequest($form, opts)
                        .done(function(response) {
                            var pageIndex = parseFloat( $(obj.inputPageIndex).val() );
                            var pageSize = parseFloat( $(obj.inputPageSize).val() );

                            if ( response.Result ) {
                                $(DOM.inputPageIndex).val( pageIndex + 1 );

                                if ( $(DOM.target + ' ' + DOM.item).length + pageSize >= response.Total ) {
                                    $(DOM.loadMore).attr('hidden', '');
                                }

                                dataView(response);
                            } else {
                                console.log('announcements-else');
                            }
                        }).fail(function() {
                            console.log('announcements-fail');
                        });

                };

                var dataView = function (data) {
                    var DOM, template, rendered;
                    DOM = obj;

                    template = $(DOM.mustache).html();
                    window.Mustache.parse(template);
                    rendered = window.Mustache.render(template, data);
                    $(DOM.target).append(rendered);
                };


                $(document).on('click', obj.loadMore, function() {
                    getData();
                });
            },
            generalAssembly: function(opts) {
                var obj = {
                    mustache: '#mustache-general-assembly',
                    loadMore: '.general-assembly-load-more',
                    page: '.page-general-assembly',
                    target: '.tables-wrapper',
                    inputPageIndex: '.page-index-general-assembly',
                    inputPageSize: '.page-size-general-assembly'
                };

                var getData = function () {
                    var DOM, $form;
                    DOM = obj;
                    $form = $(obj.page).find('form');

                    APP.Helper.sendRequest($form, opts)
                        .done(function(response) {
                            var pageIndex = parseFloat( $(DOM.inputPageIndex).val() );
                            var pageSize = parseFloat( $(DOM.inputPageSize).val() );

                            if ( response.Result ) {
                                $(DOM.inputPageIndex).val( pageIndex + 1 );

                                if ( $(DOM.target).children().length + pageSize >= response.Total ) {
                                    $(DOM.loadMore).attr('hidden', '');
                                }

                                dataView(response);
                            } else {
                                console.log('general-assembly-else');
                            }
                        }).fail(function() {
                            console.log('general-assembly-fail');
                        });
                };

                var dataView = function (data) {
                    var DOM, template, rendered;
                    DOM = obj;

                    template = $(DOM.mustache).html();
                    window.Mustache.parse(template);
                    rendered = window.Mustache.render(template, data);
                    $(DOM.target).append(rendered);
                };


                $(document).on('click', obj.loadMore, function() {
                    getData();
                });
            },
            search: function(opts) {
                var obj = {
                    mustache: '#mustache-search',
                    loadMore: '.search-load-more',
                    page: '.page-search',
                    target: '.search-result',
                    item: '.item',
                    inputPageIndex: '.page-index-search',
                    inputPageSize: '.page-size-search'
                };

                var getData = function () {
                    var DOM, $form;
                    DOM = obj;
                    $form = $(obj.page).find('form');

                    APP.Helper.sendRequest($form, opts)
                        .done(function(response) {
                            if ( response.Result ) {
                                var pageIndex = parseFloat( $(DOM.inputPageIndex).val() );
                                var pageSize = parseFloat( $(DOM.inputPageSize).val() );
                                $(DOM.inputPageIndex).val( pageIndex + 1 );

                                if ( $(DOM.target + ' ' + DOM.item).length + pageSize >= response.Total ) {
                                    $(DOM.loadMore).attr('hidden', '');
                                }

                                dataView(response);
                            } else {
                                console.log('search-else');
                            }
                        }).fail(function() {
                            console.log('search-fail');
                        });
                };

                var dataView = function (data) {
                    var DOM, template, rendered;
                    DOM = obj;

                    template = $(DOM.mustache).html();
                    window.Mustache.parse(template);
                    rendered = window.Mustache.render(template, data);
                    $(DOM.target).append(rendered);
                };


                $(document).on('click', obj.loadMore, function() {
                    getData();
                });
            },
            reports: function(opts) {
                var obj = {
                    mustache: '#mustache-reports',
                    loadMore: '.reports-load-more',
                    page: '.page-reports',
                    target: '.reports',
                    item: '.list-item',
                    inputPageIndex: '.page-index-reports',
                    inputPageSize: '.page-size-reports',
                    inputCategory: '.page-category-reports'
                };

                var getData = function () {
                    var DOM, $form;
                    DOM = obj;
                    $form = $(obj.page).find('form');

                    APP.Helper.sendRequest($form, opts)
                        .done(function(response) {
                            if ( response.Result ) {
                                var pageIndex = parseFloat( $(DOM.inputPageIndex).val() );
                                var pageSize = parseFloat( $(DOM.inputPageSize).val() );
                                $(DOM.inputPageIndex).val( pageIndex + 1 );

                                if ( $(DOM.target + ' ' + DOM.item).length + pageSize >= response.Total ) {
                                    $(DOM.loadMore).attr('hidden', '');
                                }

                                dataView(response);
                            } else {
                                console.log('reports-else');
                            }
                        }).fail(function() {
                            console.log('reports-fail');
                        });
                };

                var dataView = function (data) {
                    var DOM, template, rendered;
                    DOM = obj;

                    template = $(DOM.mustache).html();
                    window.Mustache.parse(template);
                    rendered = window.Mustache.render(template, data);
                    $(DOM.target).append(rendered);
                };

                $(document).on('click', obj.loadMore, function() {
                    getData();
                });
            },
            modal: function () {
                $(window).on('load', function() {
                    $('.modal.open').modal('show');
                });
            },
            onlyNumberInput: function () {
                $('.only-number').keypress(function (e) {
                    // if the letter is not digit then display error and don't type anything
                    return !(e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57));
                });
            },
            onlyAlphaInput: function () {
                $('.only-alpha').keypress(function (e) {
                    return (/[a-z\s]|[A-Z]|[çşöüğıÇŞÖÜĞİ]/g.test(e.key));
                });
            },
            tableStriped2n2: function() {

                function check(method) {
                    var index = $(this).index();
                    var dir = index % 2 === 0 ? 'next' : 'prev';
                    $(this).add($(this)[dir]())[method]('hover');
                }

                $('.table-striped-2n2 tbody').on('mouseenter', 'tr', function() {
                    check.bind(this)('addClass');
                }).on('mouseleave', 'tr', function() {
                    check.bind(this)('removeClass');
                });
            }
        },
        INIT: function(options) {
            // APP init

            options = options || {};

            var fn;

            for (var i in this.init) {
                if ( Object.prototype.hasOwnProperty.call(this.init, i) && i.charAt(0) !== '_' && typeof(fn = this.init[i]) === 'function' ) {
                    fn.call(this, options && options[i] || options);
                }
            }

            return this;
        }
    });

})(window.APP = window.APP || {});

$(function() {
    'use strict';

    APP.browser = (function() {

        var is = APP.Helper.is,
            val, tmp,
            userAgent = APP.sanitizeXss(navigator.userAgent);

        var browser = {
            mobile: !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(userAgent),
            ios: !!/iPhone|iPad|iPod/i.test(userAgent),
            ie: (tmp = userAgent.toLowerCase().match(/MSIE ([^;]+)|rv:(11)\.[0-9]+/i)) ? parseInt(tmp[1] || tmp[2], 10) : false,
            edge: (tmp = userAgent.indexOf('Edge/')) > 0 ? parseInt(userAgent.substring(tmp + 5, userAgent.indexOf('.', tmp)), 10) : false,
            bp: function() {
                return $(window).width() < APP.breakpoint;
            }
        };

        var $el = $('html'); // document.documentElement

        for (var k in browser ) {
            if ( Object.prototype.hasOwnProperty.call(browser, k) ) {
                val = browser[k];

                if ( val && !is.function(val) ) {
                    $el.addClass(k);
                    if ( !is.boolean(val) ) {
                        $el.addClass(k + val);
                    }
                }
            }
        }

        APP.browser = browser;

        return browser;
    }());

    APP.scriptPath = APP.Helper.getScriptPath(['app.js', 'app.min.js', 'main.js']);

    APP.Helper.loadScript(APP.scriptPath + 'config.js', {
        success: function() {
            APP.INIT(CONFIG);
        },
        failed: function() {
            APP.INIT();
        }
    });
});
