!(function (root, factory) {
    'use strict';

    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof exports === 'object') {
        // Node/CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Browser globals
        root.APP = root.APP || {};
        factory(root.APP, root.jQuery);
    }
}(this, function (APP, $) {
    'use strict';
    // Main Manager Object
    var ServiceManager = new function () {
        var self = this;

        this.defaultData = {};

        this.setDefaultData = function (data) {
            var self = this;

            Object.assign(self.defaultData, data);
        };

        // Service constructor function when create a service
        var Service = function (options) {
            this.name = options.name;
            this.url = options.url;
            this.parameters = [];
            this.async = options.async;
            this.dataType = options.dataType;
            this.callback = null;
            this.ajax = $.ajax;
            this.requestStack = [];

            // // General Setup of the $.Ajax
            $.ajaxSetup({
                // Prevents CORS from another domain
                // headers: {
                //     'X-Requested-With': 'XMLHttpRequest'
                // },

                // anti forgery token
                data: self.defaultData
            });

            function preprocessData(data) {

                var tmp = Object.assign({}, data);

                for (var key in tmp) {
                    if (tmp.hasOwnProperty(key)) { // eslint-disable-line
                        if (typeof tmp[key] === 'function') {
                            tmp[key] = tmp[key]();
                        }
                    }
                }

                return tmp;
            }

            // service request function as ajax
            this.request = function (options) {
                var self = this;

                return this.ajax({
                    url: options.url,
                    method: options.method,
                    dataType: options.dataType,
                    async: options.async,
                    beforeSend: function (xhr) {

                        // Checks request stack and prevents the same requests;
                        var isEqual = false;

                        for (var i = 0; i < self.requestStack.length; i++) {
                            var r = self.requestStack[i];

                            if (r.url === options.url && r.method === options.method && r.data === options.data) {
                                r.xhr.abort();
                                isEqual = true;
                            }
                        }

                        if (!isEqual) {
                            xhr.stackId = Math.round(Math.random() * 99999999);
                            self.requestStack.push({ url: options.url, method: options.method, data: options.data, xhr: xhr });
                        }

                    },
                    // data: JSON.stringify(options.data)
                    data: preprocessData(options.data)
                }).done(function (data, text, xhr) {
                    // removes the request when complete
                    self.requestStack = self.requestStack.filter(function (val) {
                        return val.xhr.stackId !== xhr.stackId;
                    });
                });
            };

            // Generates Dynamic Methods
            // @methods: the configuration object of all methods at the service
            this.generateMethods = function (methods) {
                for (var method in methods) {

                    if (methods.hasOwnProperty(method)) { // eslint-disable-line
                        this[method] = function (defaults, methodName, parameters, overrideDefaults) {

                            overrideDefaults = overrideDefaults || {};
                            defaults = Object.assign(defaults, overrideDefaults);

                            if (typeof defaults.async === 'undefined') {
                                defaults.async = true;
                            }

                            if (typeof defaults.dataType === 'undefined') {
                                defaults.dataType = 'json';
                            }

                            // if you did not define any requrired parameter as default
                            if (typeof defaults.parameters === 'undefined' || !defaults.parameters.length) {
                                parameters = parameters || {};
                                return this.request({
                                    method: defaults.method,
                                    dataType: defaults.dataType,
                                    data: parameters,
                                    url: defaults.url,
                                    async: defaults.async
                                });
                            }

                            // if you do not define parameters object or did not define any required parameter
                            if (typeof parameters === 'undefined' || !Object.keys(parameters).length) {
                                throw 'You did not define any parameters. You need to define ' + defaults.parameters.join(', ') + ' for ' + methodName + ' method in ' + this.name + ' service!';
                            }

                            // if you forgot to define some parameters
                            for (var i in defaults.parameters) {
                                if (typeof parameters[defaults.parameters[i]] === 'undefined') {
                                    throw defaults.parameters[i] + ' did not defined as parameter for ' + methodName + ' method in ' + this.name + ' service!';
                                }
                            }

                            // request execution
                            return this.request({
                                method: defaults.method,
                                data: parameters,
                                url: defaults.url,
                                async: defaults.async,
                                dataType: defaults.dataType
                            });
                            // curry for the function
                        }.bind(this, methods[method], method);
                    }
                }
            };
        };

        // Creates a Service
        this.registerService = function (options) {
            var serviceName = options.name;

            // creates new service with specified options
            this[serviceName] = new Service(options);
            // generates the methods that specifield in options for the service
            this[serviceName].generateMethods(options.methods);
        };
    };

    // Globalization of the main service object
    window.ServiceManager = ServiceManager;
    APP.ServiceManager = ServiceManager;
}));
